const getters: any = {
	device: state => state.app.device,
	adminMenuList: state => state.ftPermissionAdmin.adminMenuList,
	// 数据字典
	dict_datas: state => state.dict.dictDatas,
	permission_routes: state => state.ftPermissionAdmin.routes,
	// token
	token: state => state.bjftUserAdmin.adminToken,
	// 权限集
	btnPermissions: state => state.ftPermissionAdmin.btnPermissions,
}
export default getters
