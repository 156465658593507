import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass('app-main' + ($setup.device === 'mobile' ? ' app-main-mobile' : ''))
  }, [
    (_openBlock(), _createBlock(_component_router_view, { key: $setup.key }, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, {
          name: "fade-transform",
          mode: "out-in",
          appear: ""
        }, {
          default: _withCtx(() => [
            ($setup.router.meta.keepAlive)
              ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent($options.wrap(route.path, Component)), {
                    key: route.fullPath
                  }))
                ], 1024))
              : (_openBlock(), _createBlock(_resolveDynamicComponent($options.wrap(route.path, Component)), {
                  key: route.fullPath
                }))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }))
  ], 2))
}