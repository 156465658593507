import https from '../../utils/http'
import store from "@/store";
import Cache from "@/utils/cache";

const state: any = {
	adminToken: '',
	adminName: '',
	avatar: ''
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.adminToken = token
	},
	CLEAR_TOKEN: (state) => {
		state.adminToken = ''
	},
}

const actions = {
	// user logout
	logout({ commit }, state: any) {
		return new Promise((resolve, reject) => {
			https.post("/passport/logout", {}, false).then((res: any) => {
				Cache.setStorage(Cache.bjftkeyUser, null);
				Cache.setCookie(Cache.bjftKeyToken, null, null);
				Cache.setCookie(Cache.bjftKeyUuid, null, null);
				commit('CLEAR_TOKEN')
				store.dispatch('ftPermissionAdmin/adminMenuDel')
				store.dispatch('ftPermissionAdmin/clearPermission');
				store.dispatch('dict/clearDictDatas');
				resolve('ok')
				window.location.href = `${location.origin}/login.html`;
			}).catch((err: any) => {
				reject(err)
			});
		})
	},
	// SET_TOKEN
	SET_TOKEN({ commit }, token: string) {
		commit('SET_TOKEN', token)
	},
	// 通用跳转至登录页
	toLogin() {
		window.location.href = `${location.origin}/login.html`;
	},
	// 通用跳转至首页
	toHome({ commit }, state: any) {
		window.location.href = `${location.origin}/`;
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
