
import {
    defineComponent,
    ref,
    onMounted,
    reactive,
    watch,
    nextTick,
} from "vue";
import { DArrowLeft, DArrowRight } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
export default defineComponent({
    components: {
        DArrowLeft,
        DArrowRight,
    },
    name: "scrollPane",
    props: {
        visitedViews: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
    setup(props: any, context) {
        const router = useRouter();
        // 点击左右箭头监听
        const clickNum = ref<Number>(0);
        const leftNumber = ref<any>(0);
        // 盒子宽度
        const swiperScrollWidth = ref<any>(0);
        // 父级盒子的ref
        const fatherSwiper = ref<any>(null);
        const fatherWidth = ref<any>(0);
        // 盒子内容组数转换为数组
        const childrenList = reactive<any>([]);
        // 中间盒子的ref
        const swiperScrollContent = ref<any>(null);
        // 点击右箭头（左侧滚动）
        const handleRightClick = () => {
            let rightDistance =
                childrenList.value[childrenList.value.length - 1].getBoundingClientRect().left;
            if (fatherWidth.value < rightDistance) {
                if (typeof leftNumber.value === "number" && typeof fatherWidth.value === "number") {
                    leftNumber.value = leftNumber.value + fatherWidth.value;
                }
            }
        };
        // 点击左箭头（右侧滚动）
        const handleLeftClick = () => {
            let leftDistance =
                childrenList.value[0].getBoundingClientRect().left;
            if (leftDistance < 0) {
                if (typeof leftNumber.value === "number" && typeof fatherWidth.value === "number") {
                    leftNumber.value = leftNumber.value - fatherWidth.value;
                }
            }
        };
        //监听路由变化定位到当前位置
        watch(() => router.currentRoute.value, (newValue: any) => {
			props.visitedViews.forEach((item, index) => {
				if (newValue.path == item.path) {
					nextTick(() => {
						let rightDistance = childrenList.value[index].getBoundingClientRect().left;
						if (fatherWidth.value < rightDistance) {
							leftNumber.value = leftNumber.value + fatherWidth.value;
						} else if (rightDistance < 0) {
							leftNumber.value = leftNumber.value - fatherWidth.value;
						}
					});
				}
			});
        },{ immediate: true });
        onMounted(() => {
            childrenList.value = swiperScrollContent.value.children;
            swiperScrollWidth.value = Math.ceil(swiperScrollContent.value.getBoundingClientRect().width);
            fatherWidth.value = Math.ceil(fatherSwiper.value.getBoundingClientRect().width);
        });
        return {
            clickNum,
            leftNumber,
            childrenList,
            fatherSwiper,
            swiperScrollWidth,
            swiperScrollContent,
            handleRightClick,
            handleLeftClick,
        };
    },
});
