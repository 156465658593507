
/**
 * @description 侧边菜单栏页面
 */
import { computed, watch, ref, h } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// 自定义name的壳的集合
const wrapperMap = new Map();
export default {
    setup() {
        const store = useStore();
        const device = computed(() => store.state.app.device);
        const route = useRoute();
        let router = ref<any>(route);
        const key = ref<string>("/");
        //页面刷新的时候,是哪个路由就active对应菜单
        watch(() => route.path, (newValue: any) => {
            key.value = newValue;
        },{ 
            immediate: true
        });
        return {
            device,
            key,
            router,
        };
    },
    methods: {
        // 为keep-alive里的component接收的组件包上一层自定义name的壳
        wrap(fullPath: any, component: any) {
            let wrapper;
            // 重点就是这里，这个组件的名字是完全可控的，
            // 只要自己写好逻辑，每次能找到对应的外壳组件就行，完全可以写成任何自己想要的名字
            // 这就能配合 keep-alive 的 include 属性可控地操作缓存
            if (component) {
                const wrapperName = fullPath;
                if (wrapperMap.has(wrapperName)) {
                    wrapper = wrapperMap.get(wrapperName);
                } else {
                    wrapper = {
                        name: wrapperName,
                        render() {
                            return h("div", { className: "vaf-page-wrapper" }, component);
                        },
                    };
                    wrapperMap.set(wrapperName, wrapper);
                }
                return h(wrapper);
            }
        },
    },
};
