import Cookies from 'js-cookie'
const state:any = {
    sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false
    },
    device: 'desktop',//判断是pc端（desktop）还是移动端（mobile）
}

const mutations = {
    TOGGLE_DEVICE: (state:any, device:String) => {
        state.device = device
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
    },
}

const actions = {
    toggleDevice(context:any, device:String) {
        context.commit('TOGGLE_DEVICE', device)
    },
    closeSideBar(context:any, { withoutAnimation }) {
        context.commit('CLOSE_SIDEBAR', withoutAnimation)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
