import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72923e28"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "scroll-wrapper bj-flex-center",
  ref: "scrollWrapper"
}
const _hoisted_2 = {
  class: "bj-flexitem-1",
  ref: "fatherSwiper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DArrowLeft = _resolveComponent("DArrowLeft")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_DArrowRight = _resolveComponent("DArrowRight")!

  return (_openBlock(), _createElementBlock("div", {
    class: "scroll-container",
    ref: "scrollContainer",
    onWheel: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "left-roll bj-flex-center",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLeftClick && _ctx.handleLeftClick(...args)))
      }, [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_DArrowLeft)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "center-roll",
          ref: "swiperScrollContent",
          style: _normalizeStyle({ left: -_ctx.leftNumber + 'px' })
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4)
      ], 512),
      _createElementVNode("div", {
        class: "right-roll bj-flex-center",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleRightClick && _ctx.handleRightClick(...args)))
      }, [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_DArrowRight)
          ]),
          _: 1
        })
      ])
    ], 512)
  ], 544))
}