import Http from "@/utils/http";


// 查询字典数据列表
export function listData(query) {
  return Http.get('/dict/data/page', query)
}

// 查询字典数据详细
export function getData(query) {
  return Http.get('/dict/data/get', query)
}

// 根据字典类型查询字典数据信息
export function getDicts(query) {
  return Http.get('/dict/data/type/'+query)
}

// 新增字典数据
export function addData(data) {
  return Http.post('/dict/data/create', data)
}

// 修改字典数据
export function updateData(data) {
  return Http.post('/dict/data/update', data)
}

// 删除字典数据
export function delData(data) {
  return Http.post('/dict/data/delete', data)
}


// 查询全部字典数据列表
export function listSimpleDictDatas(query) {
  return Http.get('/dict/data/getListAll', query)
}