
// 当前前台是否为开发模式
const isDev = process.env.NODE_ENV === 'development'
let siteUrl = isDev ? 'http://192.168.0.154:8091' : 'https://mtest.bjftyx.net/sys'
let adminUrl = isDev ? 'http://192.168.0.154' : 'https://mtest.bjftyx.net/sys'
let adminApiUrl = isDev ? 'http://192.168.0.154:8082' : 'https://mtest.bjftyx.net/admin-api'
let supplierApiUrl = isDev ? 'http://192.168.0.154:8082' : 'https://mtest.bjftyx.net/merchant-api'
switch (process.env.VUE_APP_APP_HOST) {
	case 'development'://测试
		siteUrl = 'https://mtest.bjftyx.net/sys'
		adminUrl = 'https://mtest.bjftyx.net/sys'
		adminApiUrl = 'https://mtest.bjftyx.net/admin-api'
		supplierApiUrl = 'https://mtest.bjftyx.net/merchant-api'
		break
	case 'production'://正式
		siteUrl = 'https://m.bjftyx.net/sys'
		adminUrl = 'https://m.bjftyx.net/sys'
		adminApiUrl = 'https://m.bjftyx.net/admin-api'
		supplierApiUrl = 'https://m.bjftyx.net/merchant-api'
		break
}
export default {
	pageSize: 10,
	// siteUrl: isDev ? 'http://192.168.0.213:8091' : 'https://m.bjftyx.net/sys',
	// adminUrl: isDev ? 'http://192.168.0.213' : 'https://m.bjftyx.net/sys',
	// adminApiUrl: isDev ? 'http://192.168.0.213:8082' : 'https://m.bjftyx.net/admin-api',
	// supplierApiUrl: isDev ? 'http://192.168.0.213:8082' : 'https://m.bjftyx.net/merchant-api',//文件上传

	siteUrl: siteUrl,
	adminUrl: adminUrl,
	adminApiUrl: adminApiUrl,
	supplierApiUrl: supplierApiUrl,//文件上传
	isDev: true,
}
