import https from '../../utils/http'
import Layout from '@/layout/index.vue'
import ParentView from '@/components/ParentView/index.vue';
import router from "@/router";
import Cache from "@/utils/cache";
import { ElMessage } from "element-plus";
import store from "@/store";

const state: any = {
	adminMenuList: [],
	adminRouteMap: [],
	adminMenuLevel: {},
	menuShape: false,
	btnPermissions: [],
	rolePermissions: [],
}
const mutations = {
	ADD_MENU: (state: any, device) => {
		state.adminMenuList = device
	},
	ADD_SHOW: (state: any, device: Boolean) => {
		state.menuShape = device
	},
	DEL_MENU: (state: any) => {
		state.adminMenuList = []
		sessionStorage.clear()
	},
	TOGGLE_DEVICE: (state: any, device: String) => {
		state.adminMenuLevel = device
	},
	DEL_TOGGLE_DEVICE: (state: any) => {
		state.adminMenuLevel = {}
	},
	SET_ROUTE_MAP(state, routers) {
		state.adminRouteMap = routers
		// 为了防止用户刷新页面导致动态创建的路由失效，将其存储在本地中
		sessionStorage.setItem('adminRouteMap', JSON.stringify(routers));
	},
	SET_DYNAMIC_ROUTE_MAP(state, routers) {
		let routerMaps = filterRouter(routers)
		// 最后追加404路由
		routerMaps.push({
			path: "/:catchAll(.*)*",
			component: Layout,
			redirect: "/404",
			children: [
				{
					path: '/404',
					name: '404',
					component: () => import('@/views/404.vue'),
					meta: {
						title: '找不到页面',
					}
				},
			]
		})
		// 追加路由
		// 这块是重点，如果直接使用addRoute是无效的
		routerMaps.forEach(item => {
			router.addRoute(item);
		})
	},
	// 设置按钮权限
	SET_BTN_PERMISSIONS: (state: any, btnPermissions: any) => {
		state.btnPermissions = btnPermissions
	},
	DEL_BTN_PERMISSIONS: (state: any) => {
		state.btnPermissions = []
	},
	SET_ROLE_PERMISSIONS: (state, rolePermissions) => {
		state.rolePermissions = rolePermissions
	},
	DEL_ROLE_PERMISSIONS: (state: any) => {
		state.rolePermissions = []
	},
}
const actions = {
	levelDevice(context: any, device: String) {
		context.commit('TOGGLE_DEVICE', device)
	},
	adminMenuAdd(context: any, device: String) {
		return new Promise((resolve, reject) => {
			const adminRouteMap = JSON.parse(sessionStorage.getItem('adminRouteMap'));
			if (adminRouteMap) {
				context.commit('SET_DYNAMIC_ROUTE_MAP', adminRouteMap)
				resolve(true)
			} else {
				https.get("/passport/get-permission-info", {}, false).then((res: any) => {
					if (!res || res.menus.length <= 0 || res.roles.length <= 0) {
						ElMessage.error('您没有任何权限，请联系管理员')
						store.dispatch('bjftUserAdmin/logout')
						reject(false)
					}
					// 解构赋值
					const { menus, permissions, roles } = res
					context.commit('SET_ROUTE_MAP', menus)
					context.commit('SET_DYNAMIC_ROUTE_MAP', menus)
					context.commit('ADD_MENU', menus)
					context.commit('SET_BTN_PERMISSIONS', permissions)
					context.commit('SET_ROLE_PERMISSIONS', roles)
					resolve(true)
				})
			}
		})
	},
	showAdd(context: any, device: Boolean) {
		context.commit('ADD_SHOW', device)
	},
	adminMenuDel(context: any) {
		context.commit('DEL_MENU')
		context.commit('DEL_TOGGLE_DEVICE')
	},
	clearPermission(context: any) {
		context.commit('DEL_BTN_PERMISSIONS')
		context.commit('DEL_ROLE_PERMISSIONS')
	},
}

// 遍历后台传来的路由字符串，转换为组件对象
const filterRouter = (routers) => {
	return routers.filter((router) => {
		// 处理 meta 属性
		router.meta = {
			title: router.name,
			icon: router.icon,
			keepAlive: !router.keepAlive,
		}
		router.hidden = !router.visible
		// 区分布局与视图文件，因为加载方式不同
		if (router.children && router.children.length > 0) {
			router.component = Layout
			if (router.parentId === 0) {
				router.component = Layout
			} else {
				router.component = ParentView
			}
		} else {
			// view
			router.component = loadView(router.component)
		}

		// 判断是否存在子路由，并递归调用自己
		if (router.children && router.children.length) {
			router.children = filterRouter(router.children)
		}
		return true
	})
}
export const loadView = (view) => { // 路由懒加载
	// console.log(`@/views/${view}.vue`)
	return () => import(`../../views/${view}.vue`)
	// return (resolve) => require([`@/views/${view}.vue`], resolve)
}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}
