
import { defineComponent, computed, ref, reactive, inject } from "vue";
import Cache from "@/utils/cache";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox, FormRules, FormInstance } from "element-plus";
import {
    MoreFilled,
    ArrowLeftBold,
    ArrowDown,
    Lock,
} from "@element-plus/icons-vue";

export default defineComponent({
    components: {
        MoreFilled,
        ArrowLeftBold,
        ArrowDown,
        Lock,
    },
    setup() {
        const handleExit = () => {
            localStorage.removeItem("token");
        };
        const store = useStore();
        const ruleFormRef = ref<FormInstance>();
        let userInfo = computed(() => Cache.getStorage(Cache.bjftkeyUser));
        const menuShow = computed(
            () => store.state.ftPermissionAdmin.menuShape
        );
        let passwordShow = ref<any>(false);
        // 修改密码参数
        let passwordInfo = ref<any>({
            password: "",
            repassword: "",
        });
        //表单验证
        const rules = reactive<FormRules>({
            password: [
                {
                    required: true,
                    message: "请输入密码",
                    trigger: "blur",
                },
                {
                    min: 6,
                    max: 20,
                    message: "密码长度必须在6-20位之间",
                    trigger: "blur",
                },
            ],
            repassword: [
                {
                    validator: (rule: any, value: any, callback: any) => {
                        if (value !== passwordInfo.value.password) {
                            callback(new Error("两次输入密码不一致!"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ],
        });
        //退出登录
        let handleDropdown = (item) => {
            if (item == "logout") {
                ElMessageBox.confirm("确定要退出登录", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        store.dispatch("bjftUserAdmin/logout");
                    })
                    .catch(() => {});
            } else {
                passwordInfo.value = {
                    password: "",
                    repassword: "",
                };
                passwordShow.value = true;
            }
        };
        const global: any = inject("global");
        //修改密码
        let submitModifyPwd = async (formEl: FormInstance | undefined) => {
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                    global.https
                        .post("/passport/modifyPwd", {
                            password: passwordInfo.value.password,
                        })
                        .then((res: any) => {
                            if (res != null && res.code == "44444") {
                                ElMessage.error(res.message);
                                return;
                            }
                            ElMessage({
                                message: "修改成功",
                                type: "success",
                            });
                            passwordShow.value = false;
                            Cache.setStorage(Cache.bjftkeyUser, null);
                            window.location.href = "/login.html";
                        });
                }
            });
        };
        const switchTouch = (item) => {
            store.dispatch("ftPermissionAdmin/showAdd", item);
        };
        return {
            handleExit,
            userInfo,
            menuShow,
            passwordShow,
            passwordInfo,
            rules,
            ruleFormRef,
            handleDropdown,
            submitModifyPwd,
            switchTouch,
        };
    },
});
